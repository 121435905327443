
import { computed, defineComponent, ref, PropType } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'
import { ElMessageBox } from 'element-plus'

export enum CitizenType {
    ORGANIZATION = 'ORGANIZATION',
    INDIVIDUAL = 'INDIVIDUAL'
}
export default defineComponent({
	name: 'KycInformation',
	props: {
		citizenType: {
			type: String as PropType<CitizenType>,
			default: 'ORGANIZATION'
		},
		citizenId: {
			type: String,
			default: ''
		},
		citizenName: {
			type: String,
			default: ''
		},
		firstName: {
			type: String,
			default: ''
		},
		lastName: {
			type: String,
			default: ''
		},
		phoneNo: {
			type: String,
			default: ''
		},
		address: {
			type: String as PropType<string|null>,
			default: null
		}, 
		imageIdCard: String,
		imageBookBank: String,
		imageCompanyEvidence: String,
		isSubmiting: {
			type: Boolean as PropType<boolean>,
			default: false
		}
	},
	emits: ['onBackwardStep', 'onNextStep'],
	setup (props, { emit }) {
		const previewImageRef = ref()
		const { mobileMode: isMobile } = useWindowSize()
		const isOrganization = computed<boolean>(() => props.citizenType === 'ORGANIZATION')
		const activeImagePreviewIndex = ref(2)
		const previewImageList = computed(() => {
			const previewImageList = [
				props.imageBookBank,
				props.imageIdCard,
			]
			if (isOrganization.value) {
				previewImageList.push(props.imageCompanyEvidence)
			}

			return previewImageList
		})
		const onPreviewImage = (index: number) => {
			activeImagePreviewIndex.value = index
		}
		const onBackwardStep = () => {
			emit('onBackwardStep')
		}
		const onNextStep = () => {
			// ElMessageBox.confirm(
			// 	'ยืนยันว่าข้อมูลทั้งหมดถูกต้อง?',
			// 	{
			// 		confirmButtonText: 'ยืนยัน',
			// 		cancelButtonText: 'ยกเลิก',
			// 		type: 'success',
			// 	}
			// ).then(() => {
			emit('onNextStep', 'preview')
			// })
		}
		return {
			isMobile,
			onNextStep,
			onBackwardStep,
			previewImageRef,
			previewImageList,
			activeImagePreviewIndex,
			onPreviewImage,
			isOrganization
		}
	}
})
