
import { computed, defineComponent, PropType } from 'vue'
import { KycStatus } from '@/api/type'

export default defineComponent({
	name: 'KycInReview',
	props: {
		status: {
			type: String as PropType<KycStatus>,
			default: KycStatus.IN_REVIEW
		}
	},
	setup (props) {
		//
		const alertData = computed(() => {
			if (props.status === KycStatus.IN_REVIEW) {
				return {
					title: 'กำลังดำเนินการ',
					type: 'warning',
					description: 'ระบบจะทำการตรวจสอบข้อมูล และ เอกสารต่างๆ เพื่ออนุมัติการยืนยันตัวตนภายในเวลาประมาณ 1~2 ชั่วโมง หากเอกสารไม่ถูกต้องระบบจะแจ้งเหตุผล พร้อมทั้งให้ร้านค้าแก้ไขให้ถูกต้อง และยื่นยืนยันตัวตนอีกครั้ง'
				}
			}
			if (props.status === KycStatus.REJECT) {
				return {
					title: 'ข้อมูลไม่ถูกต้อง',
					type: 'error',
					description: 'ข้อมูลการยืนยันตัวตนไม่ครบถ้วน หรือ อาจจะไม่ถูกต้อง กรุณาตรวจสอบ และ ส่งข้อมูลอีกครั้งค่ะ'
				}
			}
			if (props.status === KycStatus.WAITING) {
				return {
					title: 'ยังไม่ได้รับการยืนยัน',
					type: 'warning',
					description: 'ยังไมไ่ด้รับข้อมูลการยืนยันจากร้านค้า'
				}
			}
			return {
				title: 'สำเร็จ',
				type: 'success',
				description: 'การยืนยันตัวตนของคุณเสร็จสมบูรณ์'
			}
		})

		return {
			alertData
		}
	}
})
