
import { computed, defineComponent, ref } from 'vue'
import CustomUpload from '@/components/core/CustomUpload.vue'

export default defineComponent({
	name: 'KycUpload',
	props: {
		citizenType: {
			type: String,
			default: 'ORGANIZATION'
		}
	},
	components: {
		CustomUpload
	},
	setup (props, { emit }) {
		const companyEvidenceImage = ref()
		const isNextStepClicked = ref(false)
		const isOrganization = computed(() => props.citizenType === 'ORGANIZATION')
		const errors = computed(() => {
			const errors: string[] = []
			// if do not click next validate wont trigger ahdn return empty error
			if (!isNextStepClicked.value) return errors
			if (isOrganization.value && !uploadData.value.companyEvidence.imageUrl) {
				errors.push('กรุณาเลือกรูปหนังสือรับรองนิติบุคคล')
			}
			if (!uploadData.value.idCard.imageUrl) {
				errors.push('กรุณาเลือกรูปบัตรประชาชน')
			}
			if (!uploadData.value.bookBank.imageUrl) {
				errors.push('กรุณาเลือกรูปหน้าสมุดบัญชี')
			}
			return errors
		})
		const uploadData = ref({
			idCard: {
				file: null,
				imageUrl: null
			},
			bookBank: {
				file: null,
				imageUrl: null
			},
			companyEvidence: {
				file: null,
				imageUrl: null
			}
		})
		const setUploadData = (newUploadData: any) => {
			uploadData.value = newUploadData
		}
		const onBackwardStep = () => {
			emit('onBackwardStep')
		}
		const onNextStep = () => {
			isNextStepClicked.value = true // trigger validate
			if (errors.value.length > 0) return
			
			emit('onNextStep', uploadData)
		}

		return {
			companyEvidenceImage,
			errors,
			uploadData,
			onNextStep,
			onBackwardStep,
			setUploadData,
			isOrganization,
		}
	}
})
