
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from 'vue'
import firebase from 'firebase/app'
import { ElNotification } from 'element-plus'
import { KycStatus } from '@/api/type'
import KycPrivacyPolicy from '@/components/shop/kyc/KycPrivacyPolicy.vue'
import KycFeatureBenefit from '@/components/shop/kyc/KycFeatureBenefit.vue'
import KycForm from '@/components/shop/kyc/KycForm.vue'
import KycUpload from '@/components/shop/kyc/KycUpload.vue'
import KycInformation from '@/components/shop/kyc/KycInformation.vue'
import KycInReview from '@/components/shop/kyc/KycInReview.vue'
import { KycPayload, uploadKyc, getKycDetail } from '@/api/shop.api'
import KycPdpa from '@/components/shop/kyc/KycPdpa.vue'
import KycOTPDialog from '@/components/shop/kyc/KycOTPDialog.vue'
import type { OTPErrorCode } from '@/constants/shop'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useAddressStore } from '@/pinia/address/address.pinia'
import dayjs from '@/utils/dayjs'

type AllStep = {
    [key: number]: string
}

export default defineComponent({
	name: 'ShopKyc',
	components: {
		KycPrivacyPolicy,
		KycForm,
		KycUpload,
		KycInformation,
		KycInReview,
		KycPdpa,
		KycOTPDialog,
		KycFeatureBenefit
	},
	setup() {
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const shopStore = useShopStore()
		const addressStore = useAddressStore()
		const allStep: AllStep = {
			0: 'benefit',
			1: 'policy',
			2: 'pdpa',
			3: 'form',
			4: 'upload',
			5: 'preview',
			6: 'inReview'
		}
		const kycFormRef = ref()
		const kycUploadRef = ref()
		const activeKey = ref<number>(0)
		const currentStep = computed(() => allStep[activeKey.value])
		const isFetching = ref(false)
		const isSubmiting = ref(false)
		const isShowOTPDialog = ref(false)
		const otpErrorCode = ref<OTPErrorCode|null>(null)
		const kycForm = reactive({
			customer: {
				citizenType: 'INDIVIDUAL',
				citizenId: '',
				citizenName: '',
				firstName: '',
				lastName: '',
				phoneNo: '',
			},
			address: {
				desc1: '',
				provinceCode: null,
				districtCode: null,
				subDistrictCode: null,
				zipCode: null
			},
			uploadData: {
				idCard: {
					file: null,
					imageUrl: null
				},
				companyEvidence: {
					file: null,
					imageUrl: null
				},
				bookBank: {
					file: null,
					imageUrl: null
				},
			},
			status: KycStatus.WAITING,
			remarkIssue: []
		})
		const displayAddress = computed(() => {
			if (!kycForm.address.provinceCode || !kycForm.address.districtCode || !kycForm.address.subDistrictCode) return ''
			const getAllName = addressStore.findAllName({
				provinceCode: kycForm.address.provinceCode as unknown as number,
				districtCode: kycForm.address.districtCode as unknown as number,
				subDistrictCode: kycForm.address.subDistrictCode as unknown as number,
			})
			return `${kycForm.address.desc1} ${getAllName?.subDistrictName} ${getAllName?.districtName} ${getAllName?.provinceName} ${kycForm.address.zipCode}`
		})
		const onBackwardStep = async () => {
			activeKey.value--
		}
		const onNextStep = async (data: any) => {
			try {
				if (currentStep.value === 'form') {
					// console.log('data', data)
					//
				}
				if (currentStep.value === 'upload') {
					kycForm.uploadData = data
				}
				if (currentStep.value === 'preview') {
					// Show OTP dialog
					isShowOTPDialog.value = true
					// await submitApi()
	
					// // Fixed Approve Status to IN REVIEW
					// kycForm.status = KycStatus.IN_REVIEW
					return
				}
				activeKey.value++
			} catch (error) {
				//
				ElNotification({
					title: 'เกิดข้อผิดพลาด',
					message: 'กรุณาตรวจสอบความถูกต้อง หรือ ติดต่อเจ้าหน้าที่',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
		}

		const submitApi = async ({ otp, reference }: { otp: string, reference: string }) => {
			try {
				// clear otp error
				otpErrorCode.value = null
				// Loading Button in information component
				isSubmiting.value = true
			
				const isOrganization = kycForm.customer.citizenType === 'ORGANIZATION'
				let payload: KycPayload = {
					type: kycForm.customer.citizenType,
					shopId: shopStore.selectedShopId as string,
					info: {
						...isOrganization && { taxId: kycForm.customer.citizenId, companyName: kycForm.customer.citizenName }, // use same key as individual
						...!isOrganization && { citizenId: kycForm.customer.citizenId },
						firstname: kycForm.customer.firstName,
						lastname: kycForm.customer.lastName,
						phoneNo: kycForm.customer.phoneNo,
					},
					address: {
						desc1: kycForm.address.desc1,
						provinceCode: kycForm.address.provinceCode,
						districtCode: kycForm.address.districtCode,
						subDistrictCode: kycForm.address.subDistrictCode,
						zipCode: kycForm.address.zipCode
					},
					filePath: {},
					reference,
					otp,
				}
				
				// Upload Image
				// eslint-disable-next-line no-unreachable
				const storageRef = firebase.storage().ref()
				const shopId = shopStore.selectedShopId
				const prefix = `kyc/${shopId}`
				const { uploadData } = kycForm
				if (uploadData.idCard.file) {
					//
					const fileExtension = (uploadData.idCard.file as any).file.name.split('.').pop()
					const idCardPath = `${prefix}/id_card/id_card_${+dayjs()}.${fileExtension}`
					await storageRef.child(idCardPath).put((uploadData.idCard.file as any).file)
					// Add To Payload
					payload = {
						...payload,
						filePath: {
							...payload.filePath,
							idCard: idCardPath
						}
					}
				}
				if (uploadData.bookBank.file) {
					const fileExtension = (uploadData.bookBank.file as any).file.name.split('.').pop()
					const bookBankPath = `${prefix}/book_bank/book_bank_${+dayjs()}.${fileExtension}`
					await storageRef.child(bookBankPath).put((uploadData.bookBank.file as any).file)
					// Add to payload
					payload = {
						...payload,
						filePath: {
							...payload.filePath,
							bookBank: bookBankPath
						}
					}
				}
				if (uploadData.companyEvidence.file) {
				//
					const fileExtension = (uploadData.companyEvidence.file as any).file.name.split('.').pop()
					const companyEvidencePath = `${prefix}/company_evidence/company_evidence_${+dayjs()}.${fileExtension}`
					await storageRef.child(companyEvidencePath).put((uploadData.companyEvidence.file as any).file)
					// Add to payload
					payload = {
						...payload,
						filePath: {
							...payload.filePath,
							companyEvidence: companyEvidencePath
						}
					}
				}
			
				await uploadKyc(payload)
				
				// close otp dialog
				isShowOTPDialog.value = false
				// // Fixed Approve Status to IN REVIEW
				kycForm.status = KycStatus.IN_REVIEW
				activeKey.value++ // go to next last step

				ElNotification({
					title: 'สำเร็จ',
					message: 'กรุณารอการยืนยัน',
					type: 'success',
					showClose: !isReactNativeWebview.value
				})
			} catch (error: any) {
				console.log('submiting kyc error', error?.response?.data)
				otpErrorCode.value = error?.response?.data?.resDesc
				return Promise.reject(error)
			} finally {
				isSubmiting.value = false
			}
		}

		watchEffect(
			() => {
				if (kycUploadRef.value) {
					kycUploadRef.value.setUploadData(kycForm.uploadData)
				}
			},
			{
				flush: 'post'
			}
		)

		const getShopKycDetail = async () => {
			isFetching.value = true
			try {
				//
				const { data } = await getKycDetail(shopStore.selectedShopId as string)
				if (data.kyc.info) {
					if (data.kyc.status === KycStatus.REJECT) {
						//
						activeKey.value = 3
					} else if (data.kyc.status === KycStatus.IN_REVIEW) {
						activeKey.value = 6
					} else if(data.kyc.status === KycStatus.APPROVE) {
						activeKey.value = 6
					} else {
						activeKey.value = 0
					}
					kycForm.customer.citizenType = data.kyc.type
					kycForm.customer.citizenId = data.kyc.info.taxId || data.kyc.info.citizenId
					kycForm.customer.citizenName = data.kyc.info.companyName
					kycForm.customer.firstName = data.kyc.info.firstname
					kycForm.customer.lastName = data.kyc.info.lastname
					kycForm.customer.phoneNo = data.kyc.info.phoneNo
					// Files Set
					const { bookBank, companyEvidence, idCard } = data.kyc.filePath
					kycForm.uploadData.bookBank.imageUrl = bookBank
					kycForm.uploadData.companyEvidence.imageUrl = companyEvidence
					kycForm.uploadData.idCard.imageUrl = idCard
					
					//ADdress Set
					kycForm.address = data.kyc.address
					// Remark issues
					kycForm.remarkIssue = data.kyc.remarkIssue
					// Status
					kycForm.status = data.kyc.status
				} else {
					// Initial First Step
					activeKey.value = 0
				}
			} catch (error) {
				console.log('error', error)
			} finally {
				isFetching.value = false
			}
		}

		onMounted(() => {
			getShopKycDetail()
		})

		return {
			isFetching,
			allStep,
			activeKey,
			onBackwardStep,
			onNextStep,
			currentStep,
			kycForm,
			kycFormRef,
			kycUploadRef,
			isSubmiting,
			displayAddress,
			isShowOTPDialog,
			submitApi,
			otpErrorCode,
		}
	}
})
