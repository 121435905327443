
import { computed, defineComponent, PropType, ref } from 'vue'
import AddressSystem from '@/components/core/AddressSystem.vue'
import { phoneTh } from '@/utils/validate'
import { useReplaceInput } from '@/use/useReplaceInput'

export enum CitizenType {
    ORGANIZATION = 'ORGANIZATION',
    INDIVIDUAL = 'INDIVIDUAL'
}
type InputDefault = any

export default defineComponent({
	name: 'KycForm',
	components: {
		AddressSystem
	},
	props: {
		citizenType: {
			type: String as PropType<CitizenType>,
			default: CitizenType.INDIVIDUAL
		},
		citizenId: {
			type: String,
			default: ''
		},
		citizenName: {
			type: String,
			default: ''
		},
		firstName: {
			type: String,
			default: ''
		},
		lastName: {
			type: String,
			default: ''
		},
		phoneNo: {
			type: String,
			default: ''
		},
		address: {
			type: String,
			default: null
		}, 
		province: {
			type: Number,
			default: null
		},
		district: {
			type: Number,
			default: null
		},
		subDistrict: {
			type: Number,
			default: null
		},
		zipCode: {
			type: Number,
			default: null
		},
		status: {
			type: String,
			default: ''
		},
	},
	emits: [
		'onNextStep', 'update:modelValue', 'update:citizenId', 'update:citizenName', 'update:firstName', 'update:lastName', 'update:phoneNo',
		'update:address', 'update:province', 'update:district', 'update:subDistrict', 'update:zipCode',
		'update:citizenType'
	],
	setup(props, { emit }) {
		const kycFormRef = ref()
		const addressSystemDom = ref()
		const isOrganization = computed(() => props.citizenType === 'ORGANIZATION')
		const { decimalValueChecker } = useReplaceInput()
		const validateRules = {
			citizenName: [
				{ required: isOrganization, message: 'กรุณาระบุชื่อนิติบุคคล', trigger: ['change', 'blur'] }
			],
			firstName: [
				{ required: true, message: 'กรุณาระบุชื่อจริง', trigger: ['change', 'blur'] }
			],
			lastName: [
				{ required: true, message: 'กรุณาระบุชื่อจริง', trigger: ['change', 'blur'] }
			],
			phoneNo: [
				{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์', trigger: ['change', 'blur']},
				{ validator: (_: unknown, value: string) => phoneTh(value), message: 'กรุณากรอกเบอร์โทรศัพท์ ขึ้นต้นด้วย (06), (08), (09)', trigger: ['change', 'blur']},
				{ min: 10, max: 10, message: 'กรุณากรอกเบอร์โทรศัพท์ให้ครบจำนวน', trigger: ['change', 'blur']},
			]
		}
		const validateAll = async () => {
			const isValid = await kycFormRef.value?.validate()
			return isValid
		}
		const onNextClick = async () => {
			const [isFormValid, isAddressValid] = await Promise.all([
				validateAll(),
				addressSystemDom.value.validateAll()
			])
			if (!isFormValid || !isAddressValid) return
			emit('onNextStep')
		}
		const isReject = computed(() => props.status === 'REJECT')
		return {
			kycFormRef,
			addressSystemDom,
			isOrganization,
			validateRules,
			isReject,
			onNextClick,
			validateAll,
			decimalValueChecker,
			
		}
	}
})
