
import { defineComponent, ref } from 'vue'
import { baseURL } from '@/config'

export default defineComponent({
	name: 'KycPdpa',
	emits: ['onNextClick', 'onBackwardStep'],
	setup(_, { emit }) {
		const isAllowed = ref(false)
		const onBackwardClick = () => emit('onBackwardStep')
		const onNextClick = () => emit('onNextClick')
		return {
			isAllowed,
			onNextClick,
			onBackwardClick,
			baseURL
		}
	}
})
