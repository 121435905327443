import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2ddf186"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "kyc-title" }
const _hoisted_3 = {
  key: 0,
  class: "kyc-step"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 3,
  class: "form-content"
}
const _hoisted_6 = {
  key: 0,
  class: "mt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_KycFeatureBenefit = _resolveComponent("KycFeatureBenefit")!
  const _component_KycPrivacyPolicy = _resolveComponent("KycPrivacyPolicy")!
  const _component_KycPdpa = _resolveComponent("KycPdpa")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_KycForm = _resolveComponent("KycForm")!
  const _component_KycUpload = _resolveComponent("KycUpload")!
  const _component_KycInformation = _resolveComponent("KycInformation")!
  const _component_KycInReview = _resolveComponent("KycInReview")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_KycOTPDialog = _resolveComponent("KycOTPDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            md: 18,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_row, {
                justify: "space-between",
                class: "mb-10"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.currentStep === 'benefit'
								? 'สิทธิประโยชน์'
								: 'ยืนยันตัวตน'), 1),
                  (_ctx.activeKey <= 4 && _ctx.currentStep !== 'benefit')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, " ขั้นตอน " + _toDisplayString(_ctx.activeKey) + "/4 ", 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_card, null, {
                default: _withCtx(() => [
                  (_ctx.isFetching)
                    ? (_openBlock(), _createBlock(_component_el_skeleton, {
                        key: 0,
                        rows: 10,
                        animated: ""
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.isFetching)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_ctx.currentStep === 'benefit')
                          ? (_openBlock(), _createBlock(_component_KycFeatureBenefit, {
                              key: 0,
                              onOnNextStep: _ctx.onNextStep
                            }, null, 8, ["onOnNextStep"]))
                          : _createCommentVNode("", true),
                        (_ctx.currentStep === 'policy')
                          ? (_openBlock(), _createBlock(_component_KycPrivacyPolicy, {
                              key: 1,
                              onOnBackwardStep: _ctx.onBackwardStep,
                              onOnNextClick: _ctx.onNextStep
                            }, null, 8, ["onOnBackwardStep", "onOnNextClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.currentStep === 'pdpa')
                          ? (_openBlock(), _createBlock(_component_KycPdpa, {
                              key: 2,
                              onOnBackwardStep: _ctx.onBackwardStep,
                              onOnNextClick: _ctx.onNextStep
                            }, null, 8, ["onOnBackwardStep", "onOnNextClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.currentStep !== 'policy' && _ctx.currentStep !== 'inReview')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              (_ctx.kycForm.remarkIssue.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kycForm.remarkIssue, (issue) => {
                                      return (_openBlock(), _createBlock(_component_el_alert, {
                                        class: "mt-1",
                                        key: issue,
                                        title: issue,
                                        type: "error",
                                        closable: false,
                                        "show-icon": ""
                                      }, null, 8, ["title"]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.currentStep === 'form')
                                ? (_openBlock(), _createBlock(_component_KycForm, {
                                    key: 1,
                                    ref: "kycFormRef",
                                    citizenType: _ctx.kycForm.customer.citizenType,
                                    "onUpdate:citizenType": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kycForm.customer.citizenType) = $event)),
                                    citizenName: _ctx.kycForm.customer.citizenName,
                                    "onUpdate:citizenName": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kycForm.customer.citizenName) = $event)),
                                    citizenId: _ctx.kycForm.customer.citizenId,
                                    "onUpdate:citizenId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kycForm.customer.citizenId) = $event)),
                                    firstName: _ctx.kycForm.customer.firstName,
                                    "onUpdate:firstName": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kycForm.customer.firstName) = $event)),
                                    lastName: _ctx.kycForm.customer.lastName,
                                    "onUpdate:lastName": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kycForm.customer.lastName) = $event)),
                                    phoneNo: _ctx.kycForm.customer.phoneNo,
                                    "onUpdate:phoneNo": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kycForm.customer.phoneNo) = $event)),
                                    address: _ctx.kycForm.address.desc1,
                                    "onUpdate:address": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kycForm.address.desc1) = $event)),
                                    province: _ctx.kycForm.address.provinceCode,
                                    "onUpdate:province": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kycForm.address.provinceCode) = $event)),
                                    district: _ctx.kycForm.address.districtCode,
                                    "onUpdate:district": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kycForm.address.districtCode) = $event)),
                                    subDistrict: _ctx.kycForm.address.subDistrictCode,
                                    "onUpdate:subDistrict": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kycForm.address.subDistrictCode) = $event)),
                                    zipCode: _ctx.kycForm.address.zipCode,
                                    "onUpdate:zipCode": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kycForm.address.zipCode) = $event)),
                                    status: _ctx.kycForm.status,
                                    onOnBackwardStep: _ctx.onBackwardStep,
                                    onOnNextStep: _ctx.onNextStep,
                                    class: "mt-10"
                                  }, null, 8, ["citizenType", "citizenName", "citizenId", "firstName", "lastName", "phoneNo", "address", "province", "district", "subDistrict", "zipCode", "status", "onOnBackwardStep", "onOnNextStep"]))
                                : _createCommentVNode("", true),
                              (_ctx.currentStep === 'upload')
                                ? (_openBlock(), _createBlock(_component_KycUpload, {
                                    key: 2,
                                    citizenType: _ctx.kycForm.customer.citizenType,
                                    onOnBackwardStep: _ctx.onBackwardStep,
                                    onOnNextStep: _ctx.onNextStep,
                                    ref: "kycUploadRef"
                                  }, null, 8, ["citizenType", "onOnBackwardStep", "onOnNextStep"]))
                                : _createCommentVNode("", true),
                              (_ctx.currentStep === 'preview')
                                ? (_openBlock(), _createBlock(_component_KycInformation, {
                                    key: 3,
                                    onOnBackwardStep: _ctx.onBackwardStep,
                                    onOnNextStep: _ctx.onNextStep,
                                    citizenType: _ctx.kycForm.customer.citizenType,
                                    citizenName: _ctx.kycForm.customer.citizenName,
                                    citizenId: _ctx.kycForm.customer.citizenId,
                                    firstName: _ctx.kycForm.customer.firstName,
                                    lastName: _ctx.kycForm.customer.lastName,
                                    phoneNo: _ctx.kycForm.customer.phoneNo,
                                    address: _ctx.displayAddress,
                                    imageBookBank: _ctx.kycForm.uploadData?.bookBank?.imageUrl,
                                    imageCompanyEvidence: _ctx.kycForm.uploadData?.companyEvidence?.imageUrl,
                                    imageIdCard: _ctx.kycForm.uploadData?.idCard?.imageUrl,
                                    isSubmiting: _ctx.isSubmiting
                                  }, null, 8, ["onOnBackwardStep", "onOnNextStep", "citizenType", "citizenName", "citizenId", "firstName", "lastName", "phoneNo", "address", "imageBookBank", "imageCompanyEvidence", "imageIdCard", "isSubmiting"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.currentStep === 'inReview')
                          ? (_openBlock(), _createBlock(_component_KycInReview, {
                              key: 4,
                              status: _ctx.kycForm.status
                            }, null, 8, ["status"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    (_ctx.isShowOTPDialog)
      ? (_openBlock(), _createBlock(_component_KycOTPDialog, {
          key: 0,
          modelValue: _ctx.isShowOTPDialog,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isShowOTPDialog) = $event)),
          "phone-number": _ctx.kycForm.customer?.phoneNo,
          isSubmiting: _ctx.isSubmiting,
          otpErrorCode: _ctx.otpErrorCode,
          onOnSubmitOTP: _ctx.submitApi
        }, null, 8, ["modelValue", "phone-number", "isSubmiting", "otpErrorCode", "onOnSubmitOTP"]))
      : _createCommentVNode("", true)
  ], 64))
}